<template lang="pug">
    div
        .row.header-block
            .col-xs-12.col-sm-6.col-md-7.title.header-filters
                dropdown-checkbox(
                    v-if="tenantsDD"
                    filter-name="Tenants"
                    :filter-options="tenants"
                    :selected-item="selectedTenants"
                    search-placeholder="Search for a tenant"
                    @select-filter="handleFilterSelect"
                    @load-more="loadMoreTenants"
                    @load-all="loadAllTenants"
                    @search="loadTenants"
                    :all-options="true"
                    :is-loading="isLoadingAllTenants"
                    ref="tenantsFilterSelect"
                )
                dropdown-checkbox(
                    v-if="clubsDD"
                    filter-name="Clubs"
                    :filter-options="clubs"
                    :selected-item="selectedClubs"
                    search-placeholder="Search for a club"
                    @select-filter="handleFilterSelect"
                    @load-more="loadMoreClubs"
                    @search="loadClubs"
                    ref="clubsFilterSelect"
                )
                dropdown-radio-button(
                  v-if="clubsRadioDD"
                    filter-name="Clubs"
                    :filter-options="clubs"
                    :selected-item="selectedClubs"
                    search-placeholder="Search for a club"
                    @select-filter="handleFilterSelect"
                    @load-more="loadMoreClubs"
                    @search="loadClubs"
                )
                dropdown-checkbox(
                    v-if="showCategories"
                    filter-name="Main"
                    :filter-options="mainCategories"
                    :selected-item="selectedMainCategories"
                    search-placeholder="Search for main categories"
                    @select-filter="handleFilterSelect"
                    @load-more="loadMoreMainCategories"
                    @search="loadMainCategories"
                    ref="mainCategoryFilterSelect"
                )
                dropdown-checkbox(
                    v-if="showCategories"
                    filter-name="Sub"
                    :filter-options="subCategories"
                    :selected-item="selectedSubCategories"
                    search-placeholder="Search for sub categories"
                    @select-filter="handleFilterSelect"
                    @load-more="loadMoreSubCategories"
                    @search="loadSubCategories"
                    ref="subCategoryFilterSelect"
                )
                dropdown-checkbox(
                    v-if="externalEventsFiltersDD"
                    filter-name="Event Filters"
                    :filter-options="monitoringEventFilters"
                    :selected-item="selectedFilters"
                    @select-filter="handleFilterSelect"
                    :is-search="false"
                )
                dropdown-checkbox(
                    v-if="eventFiltersDD"
                    filter-name="Event Filters"
                    :filter-options="eventFilters"
                    :selected-item="selectedFilters"
                    @select-filter="handleFilterSelect"
                    :is-search="false"
                    ref="eventFilterSelect"
                )
                dropdown-checkbox(
                    v-if="extensionsDD"
                    filter-name="Extensions"
                    :filter-options="extensions"
                    :selected-item="selectedExtensions"
                    @select-filter="handleFilterSelect"
                    :is-search="false"
                    ref="extensionsFilterSelect"
                )
                .header-button-block-wrapper(v-if="gridListView" @click="toggleView")
                    a.grid-view.fa(aria-hidden="true" :class="gridListViewIconClass")
                date-range-picker(
                    v-if="datePickerData"
                    format="DD/MM/YY"
                    :mode="datePickerData.mode"
                    v-model="dateRanges"
                    @input="updateQueryWithTimeRange"
                )
                .filter(v-for="(filter, index) in filterCheckboxes" :key="index")
                        checkbox(
                            v-if="!filter.hidden"
                            :value="filter.value"
                            :title="filter.title"
                            @input="updateFilterCheckbox($event, filter)"
                        ) {{ filter.title }}
            .col-xs-12.col-sm-6.col-md-5.col-lg-offset-0.pull-right.search(v-if="showSearch")
                page-search(
                    :multi-search="multiSearch"
                    :search-filters="searchFilters"
                )
                clear-filters(:filters="clearableFilters" @reset="resetEventFilter")
                .header-button-block-wrapper.pull-right(v-if="exportData" @click="exportData")
                    a.button-image-link
                        img(src="assets/images/export.svg" alt="Export")
        .separator
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
import { ClubsApi, TenantApi, CategoriesApi, ExtensionsApi } from "@/api";
import { root } from "@/constants";
import { handleActionError } from "@/utils";

import PageSearch from "./PageSearch.vue";
import ClearFilters from "./ClearFilters.vue";
import DropdownCheckbox from "./DropdownCheckbox.vue";
import DropdownRadioButton from "./DropdownRadioButton.vue";

import { Checkbox, DateRangePicker } from "@/components/shared";
import { categories, stateRefs, events } from "@/constants";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
const { TENANT_LOAD_LIMIT, CLUB_STATUSES_DROPDOWN_FILTER } = root;

const { MAIN, SUB } = categories[stateRefs.category].types;
const { VOD, DELETED, MOVING_TO_VOD, LIVE } = events[stateRefs.event].statuses;

export default {
  name: "PxltHeader",
  components: {
    ClearFilters,
    PageSearch,
    DropdownCheckbox,
    DropdownRadioButton,
    Checkbox,
    DateRangePicker,
  },
  props: {
    tenantsDD: {
      type: Boolean,
      default: false,
    },
    clubsDD: {
      type: Boolean,
      default: false,
    },
    clubsRadioDD: {
      type: Boolean,
      default: false,
    },
    eventFiltersDD: {
      type: Boolean,
      default: false,
    },
    categoriesDD: {
      type: Boolean,
      default: false,
    },
    multiSearch: {
      type: Boolean,
      default: false,
    },
    dateSelect: {
      type: Boolean,
      default: false,
    },
    gridListView: {
      type: Boolean,
      default: false,
    },
    datePickerData: {
      type: Object,
      default: null,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    filterCheckboxes: {
      type: Array,
      default: () => [],
    },
    searchFilters: {
      type: Array,
      default: () => [],
    },
    clearableFilters: {
      type: Array,
      default: () => [],
    },
    exportData: {
      type: Function,
      default: null,
    },
    externalEventsFiltersDD: {
      type: Boolean,
      default: false,
    },
    extensionsDD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const selectedTenants = (this.$route.query.tenant && this.$route.query.tenant.split(",")) || [];
    const eventsFilterNames = [
      "hideTest",
      "showTest",
      "hasMlPersonalHighlights",
      "isBetting",
      "hasPersonalHighlights",
      "geniusIntegration",
      "tournamentMode",
      "isExternalContent",
      "highlight",
      "vodDeletedAt",
      "statusDeleted",
      "hasPano",
      "noPano",
      "hideTestNames",
      "has5Players",
      "has8Players",
      "has11Players",
      "movingToVOD",
      "hasGeoBlocking",
      "noGeoBlocking",
      "hasMixerHighlights",
      "minDuration",
      "maxDuration",
      "isMale",
      "isFemale",
      "isMixed",
    ];
    const monitoringFilterNames = ["showExternal", "hideExternal"];
    const selectedFilters = [];

    const filterNames = this.externalEventsFiltersDD ? monitoringFilterNames : eventsFilterNames;
    const deletedAndMovingToVodStatuses = [MOVING_TO_VOD, DELETED].every((item) =>
      this.$route.query?.status?.split(",").includes(item),
    );

    filterNames.forEach((item) => {
      if (
        ((this.$route.query.status === DELETED || deletedAndMovingToVodStatuses) &&
          item === "statusDeleted") ||
        ((this.$route.query.status === MOVING_TO_VOD || deletedAndMovingToVodStatuses) &&
          item === "movingToVOD")
      ) {
        selectedFilters.push(item);
      }

      this.$route.query[item] ? selectedFilters.push(item) : selectedFilters;
    });

    let clubs = [];

    let selectedClubs = (this.$route.query.clubs && this.$route.query.clubs.split(",")) || [];

    if (this.isClubAdmin) {
      const hasQueryClub = (club) => this.$route.query.clubs.includes(club.id);

      clubs = this.userAdminClubs;
      selectedClubs = this.userAdminClubs.filter(hasQueryClub).map((item) => item.id);
    }

    const dateRanges =
      this.$route.query.startDate && this.$route.query.endDate
        ? [
            moment.unix(this.$route.query.startDate).toDate(),
            moment.unix(this.$route.query.endDate).toDate(),
          ]
        : this.datePickerData?.mode === "past"
        ? this.setVodDefaultDate()
        : [];

    return {
      activeView: "list",
      clubOffset: 0,
      tenantOffset: 0,
      mainCategoryOffset: 0,
      subCategoryOffset: 0,

      clubs,
      tenants: [],
      allTenants: [],
      shortEventFilters: [
        { name: "Show Hidden OTT Events", id: "showTest" },
        { name: "Show NON Hidden OTT Events", id: "hideTest" },
        { name: "ML highlights", id: "hasMlPersonalHighlights" },
        { name: "Crowdsource highlights events", id: "hasPersonalHighlights" },
        { name: "Betting events", id: "isBetting" },
        { name: "Tournament mode events", id: "tournamentMode" },
        { name: "Genius Integration events", id: "geniusIntegration" },
        { name: "External Content events", id: "isExternalContent" },
        { name: "Events with Pano", id: "hasPano" },
        { name: "Events without Pano", id: "noPano" },
        { name: "Non test (name) events", id: "hideTestNames" },
        { name: "Soccer Events 5X5", id: "has5Players" },
        { name: "Soccer Events 8X8", id: "has8Players" },
        { name: "Soccer Events 11X11", id: "has11Players" },
        { name: "Events longer than 30 minutes", id: "minDuration" },
        { name: "Events shorter than 3 hours", id: "maxDuration" },
        { name: "Male Events", id: "isMale" },
        { name: "Female Events", id: "isFemale" },
        { name: "Co-ed Events", id: "isMixed" },
      ],
      defaultVodEventFilters: [
        { name: "Highlights", id: "highlight" },
        { name: "Mixer highlights", id: "hasMixerHighlights" },
        { name: "Events to be deleted", id: "vodDeletedAt" },
        { name: "Moving-to-vod Events", id: "movingToVOD" },
      ],
      deletedEventFilter: [{ name: "Deleted Events", id: "statusDeleted" }],
      monitoringEventFilters: [
        { name: "Internal Events", id: "hideExternal" },
        { name: "External Events", id: "showExternal" },
      ],
      geoBlockingEventFilters: [
        { name: "Geo location events", id: "hasGeoBlocking" },
        { name: "Non Geo location events", id: "noGeoBlocking" },
      ],
      selectedClubs,
      selectedTenants,
      currentTenant: null,
      isLoadingAllTenants: false,
      isAllTenantsLoaded: false,
      selectedFilters,
      filterNames,
      dateRanges,
      mainCategories: [],
      subCategories: [],
      selectedMainCategories: [],
      selectedSubCategories: [],
      extensions: [],
      selectedExtensions: [],

      isFirstLoad: true,
    };
  },
  computed: {
    ...mapUserGetters([
      "isBillingOrSuperAdmin",
      "isSuperAdmin",
      "isClubAdmin",
      "isAccountAdmin",
      "userAdminClubs",
      "currentUser",
    ]),

    allowSystemsManagement() {
      return this.currentUser?.attributes?.permissions?.allowSystemsManagement;
    },

    vodEventFilters() {
      return this.isBillingOrSuperAdmin || this.isAccountAdmin
        ? this.deletedEventFilter.concat(this.defaultVodEventFilters)
        : this.defaultVodEventFilters;
    },

    eventFilters() {
      const eventFilters =
        this.isAccountAdmin && this.isOTTTenant
          ? this.shortEventFilters.concat(this.geoBlockingEventFilters)
          : this.shortEventFilters;

      return this.$route.query.status
        .split(",")
        .some((item) => [VOD, DELETED, MOVING_TO_VOD].includes(item))
        ? this.vodEventFilters.concat(eventFilters)
        : eventFilters;
    },

    gridListViewIconClass() {
      return this.activeView === "list" ? "fa-bars" : "fa-table";
    },

    isOTTTenant() {
      return !!this.currentTenant?.applicationSettings?.contentConsumption?.ott;
    },

    isAllTenantsSelected() {
      return this.selectedTenants.length === this.allTenants.length && this.allTenants.length;
    },

    showCategories() {
      return this.isOTTTenant && this.categoriesDD;
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        this.activeView = route.query.view ? route.query.view : "list";
        this.syncFilterCheckboxesWithQuery();
        this.syncDatesRangeWithQuery();

        const deletedAndMovingToVodStatuses = this.$route.query?.status
          ?.split(",")
          .every((item) => [MOVING_TO_VOD, DELETED].includes(item));

        if (
          this.vodEventFilters.some((item) => this.selectedFilters.includes(item.id)) &&
          this.$route.query.status !== VOD &&
          this.$route.query.status !== DELETED &&
          this.$route.query.status !== MOVING_TO_VOD &&
          !deletedAndMovingToVodStatuses
        ) {
          this.vodEventFilters.forEach((filter) => {
            const index = this.selectedFilters.findIndex((item) => item === filter.id);

            this.$updateQuery({ [filter.id]: undefined });
            delete this.selectedFilters[index];
          });
        }

        // reset Event Filters after clicking on Event tab
        if (
          !Object.keys(route.query).some((key) => this.selectedFilters.includes(key)) &&
          this.$route.query.status !== MOVING_TO_VOD &&
          this.$route.query.status !== DELETED &&
          !deletedAndMovingToVodStatuses
        ) {
          this.handleEventFiltersSelect();
        }

        // reset Clubs after clicking on Event tab
        if (!this.$route.query?.clubs) {
          this.handleClubsSelect();
        }

        // reset Main Categories and  Sub Categories after clicking on Event tab
        if (!this.$route.query?.categories) {
          this.handleSubCategoriesSelect();
          this.handleMainCategoriesSelect();
        }

        // reset Extensions after clicking on Event tab
        if (!this.$route.query?.extensions) {
          this.handleExtensionsSelect();
        }
      },
    },
    "$route.query.tenant": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal && this.isAccountAdmin) {
          this.setCurrentTenant();
          this.loadMainCategories();
          this.loadSubCategories();
          this.loadExtensions();

          if (this.$route.query.clubs) {
            this.selectedClubs = [];
            this.$updateQuery({ clubs: undefined });
          }
        }

        this.loadClubs();
      },
    },
    datePickerData() {
      if (this.datePickerData.mode === "past") {
        this.dateRanges = this.setVodDefaultDate();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    setVodDefaultDate() {
      return [moment().subtract(90, "days").toDate(), moment().toDate()];
    },
    toggleView() {
      const view = this.activeView === "list" ? "grid" : "list";

      this.$updateQuery({ view });
    },
    async init() {
      if (this.tenantsDD) {
        this.loadTenants();
      }

      if (this.clubsDD || this.clubsRadioDD) {
        this.loadClubs();
      }

      if (this.extensionsDD) {
        this.loadExtensions();
      }

      if (this.selectedTenants) {
        this.$emit("selected-tenants", this.selectedTenants);
      }

      if (this.isAccountAdmin) {
        await this.setCurrentTenant();
        this.loadMainCategories();
        this.loadSubCategories();

        if (this.$route.query.categories) {
          const queryCategoriesIds = this.$route.query.categories.split(",");
          const mainCategoriesIds = this.mainCategories.map((category) => category.id);
          const subCategoriesIds = this.subCategories.map((category) => category.id);
          const allCategoriesIds = [...mainCategoriesIds, ...subCategoriesIds];
          const missedCategoriesIds = queryCategoriesIds.filter(
            (item) => !allCategoriesIds.includes(item),
          );

          if (missedCategoriesIds.length) {
            return CategoriesApi.getCategories({
              ids: missedCategoriesIds,
              hidden: false,
              archived: false,
            })
              .then((response) => {
                response.items?.forEach((category) => {
                  const { type } = category.attributes;

                  if (type === MAIN) {
                    this.selectedMainCategories.push(category.id);
                  } else if (type === SUB) {
                    this.selectedSubCategories.push(category.id);
                  }
                });
              })
              .catch((error) => handleActionError(error, this, "getting categories"));
          }
        }
      }
    },
    async setCurrentTenant() {
      const nextTenant = await TenantApi.getTenant(this.$store.state.user.activeTenant).catch(
        (error) => {
          handleActionError(error, this, "getting tenant");

          return null;
        },
      );

      if (nextTenant) {
        this.currentTenant = nextTenant;
      }
    },
    updateQueryWithTimeRange(dateRanges) {
      const [startDate, endDate] = dateRanges;
      const query = {
        offset: 0,
        startDate: startDate ? moment(startDate).startOf("day").unix() : undefined,
        endDate: endDate ? moment(endDate).endOf("day").unix() : undefined,
      };

      this.$updateQuery(query);
    },
    updateFilterCheckbox(value, filter) {
      filter.value = value;

      const query = {
        offset: 0,
        [filter.name]: filter.queryValue(),
      };

      this.$updateQuery(query);
    },
    syncFilterCheckboxesWithQuery() {
      this.filterCheckboxes.forEach((filter) => {
        const queryFilter = this.$route.query[filter.name];

        filter.value = queryFilter
          ? filter.name === "status"
            ? queryFilter === "live"
            : true
          : false;
      });

      this.$forceUpdate();
    },
    syncDatesRangeWithQuery() {
      if (this.dateRanges.length && !this.$route.query.startDate && !this.$route.query.endDate) {
        this.dateRanges = [];
      }
    },
    loadClubs(options = {}) {
      const params = {
        limit: 10,
        offset: options.offset || 0,
        q: options.q || undefined,
        tenant: this.$route.query.tenant,
        clubStatus: CLUB_STATUSES_DROPDOWN_FILTER.join(","),
      };

      this.clubOffset = params.offset;

      if (!this.isClubAdmin) {
        ClubsApi.getClubs(params)
          .then((response) => {
            this.setClubs(response.items, params);
          })
          .catch((error) => handleActionError(error, this, "getting clubs."));
      } else {
        const escapeString = (str) => str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
        const pattern = params.q ? new RegExp(escapeString(params.q), "i") : "";

        this.clubs = params.q
          ? this.userAdminClubs.filter((club) => club.name.search(pattern) !== -1)
          : this.userAdminClubs;

        if (this.isFirstLoad && this.clubsRadioDD && this.allowSystemsManagement) {
          const clubId = this.$route.query.clubId || this.userAdminClubs[0]?.id;

          this.$updateQuery({ clubId });
          this.selectedClubs = [clubId];
          this.isFirstLoad = false;
        }
      }
    },
    loadTenants(options = {}) {
      const params = {
        limit: options.limit || TENANT_LOAD_LIMIT,
        offset: options.offset || 0,
        q: options.q || undefined,
      };

      // Not call the API if all tenants are already loaded
      if (this.isAllTenantsLoaded) {
        let filteredTenants = [...this.allTenants];

        if (options.q) {
          filteredTenants = this.allTenants.filter((tenant) =>
            tenant.name.toLowerCase().includes(options?.q?.toLowerCase()),
          );
        }

        this.setTenants({ items: filteredTenants, q: options.q });

        return;
      }

      this.tenantOffset = params.offset;

      TenantApi.getTenants(params)
        .then((response) => {
          this.setTenants({ items: response.items, q: options.q });
        })
        .catch((error) => handleActionError(error, this, "getting tenants"));
    },

    loadMainCategories(options = {}) {
      const specParams = {
        type: MAIN,
        limit: options.limit || 10,
        offset: options.offset || 0,
        q: options.q || undefined,
        hidden: false,
        archived: false,
      };

      this.mainCategoryOffset = specParams.offset;

      return CategoriesApi.getCategories(specParams)
        .then((response) => this.setMainCategories(response.items))
        .catch((error) => handleActionError(error, this, "getting main categories"));
    },

    loadSubCategories(options = {}) {
      const specParams = {
        type: SUB,
        limit: options.limit || 10,
        offset: options.offset || 0,
        q: options.q || undefined,
        hidden: false,
      };

      this.subCategoryOffset = specParams.offset;

      return CategoriesApi.getCategories(specParams)
        .then((response) => this.setSubCategories(response.items))
        .catch((error) => handleActionError(error, this, "getting sub categories"));
    },

    loadMoreTenants(search) {
      const options = {
        limit: TENANT_LOAD_LIMIT,
        offset: this.tenantOffset + TENANT_LOAD_LIMIT,
        q: search || undefined,
      };

      this.loadTenants(options);
    },
    loadAllTenants(search) {
      const options = {
        limit: TENANT_LOAD_LIMIT,
        offset: 0,
        q: search || undefined,
      };

      const getTenants = (options) => {
        this.isLoadingAllTenants = true;
        return TenantApi.getTenants(options)
          .then((data) => {
            this.allTenants = this.allTenants.concat(
              data.items.map((tenant) => ({ id: tenant.name, name: tenant.name })),
            );

            if (data.pagination.next) {
              options.offset = options.offset + options.limit;

              return getTenants(options);
            }

            if (!data.pagination.next && !search) {
              this.isAllTenantsLoaded = true;
            }
          })
          .catch((error) => handleActionError(error, this, "getting tenants"))
          .finally(() => {
            this.isLoadingAllTenants = false;
          });
      };

      // Not call the API if all tenants are already loaded
      if (this.isAllTenantsSelected || this.isAllTenantsLoaded) {
        return;
      }

      return getTenants(options).then(() => this.setTenants({ items: this.allTenants }));
    },
    loadMoreClubs(search) {
      let options = {
        limit: 10,
        offset: this.clubOffset + 10,
        q: search || undefined,
      };

      this.loadClubs(options);
    },

    loadMoreMainCategories(search) {
      let options = {
        limit: 10,
        offset: this.mainCategoryOffset + 10,
        q: search || undefined,
      };

      this.loadMainCategories(options);
    },

    loadMoreSubCategories(search) {
      let options = {
        limit: 10,
        offset: this.subCategoryOffset + 10,
        q: search || undefined,
      };

      this.loadSubCategories(options);
    },

    async loadExtensions() {
      const options = { limit: 100, offset: 0 };

      try {
        const data = this.isAccountAdmin
          ? await TenantApi.getApprovedExtensionsForTenant(this.$route.query.tenant)
          : await ExtensionsApi.getExtensions(options);
        const extensionsList = data.items || data;

        this.extensions = extensionsList.map(({ name, _id }) => ({ name: name, id: _id }));

        const routeExtensionsArray = this.$route.query.extensions?.split(",");

        if (routeExtensionsArray) {
          const queryExtensions = this.extensions.filter(({ id }) =>
            routeExtensionsArray.includes(id),
          );

          this.selectedExtensions = queryExtensions.map((extension) => extension.id);
        }
      } catch (error) {
        handleActionError(error, this, "getting extensions.");
      }
    },

    setClubs(clubs = [], params) {
      if (this.clubOffset === 0) {
        this.clubs = [];
      }

      const newClubs = clubs
        .filter((newClub) => this.clubs.every((club) => club.id !== newClub.id))
        .map((item) => ({ id: item.id, name: item.attributes.name }));

      this.clubs = this.clubs.concat(newClubs);

      if (this.$route.query.clubs) {
        const clubIds = this.clubs.map((item) => item.id);
        const missedClubs = (this.selectedClubs || [])
          .filter((id) => clubIds.indexOf(id) === -1)
          .join(",");

        if (missedClubs && !params.q) {
          ClubsApi.getClubs({
            id: missedClubs,
            clubStatus: CLUB_STATUSES_DROPDOWN_FILTER.join(","),
          })
            .then((response) => {
              const clubs = response.items.map((club) => ({
                id: club.id,
                name: club.attributes.name,
              }));

              this.clubs = this.clubs.concat(clubs);
            })
            .catch((error) => handleActionError(error, this, "getting clubs"));
        }
      }
    },
    setTenants({ items: tenants, q } = []) {
      if (this.tenantOffset === 0) {
        this.tenants = [];
      }

      // Set all tenants if all tenants are loaded
      if (this.isAllTenantsLoaded) {
        this.tenants = tenants;

        return;
      }

      let newTenants = [];
      const selected = this.selectedTenants;
      const coming = tenants.map((tenant) => tenant.name);
      const existing = this.tenants.map((tenant) => tenant.name);

      [...selected, ...coming].map((tenant) => {
        const alreadyExist = existing.includes(tenant) || newTenants.find((t) => t.id === tenant);

        if (!alreadyExist) {
          newTenants.push({ id: tenant, name: tenant });
        }
      });

      this.tenants = this.tenants
        .concat(newTenants)
        .filter((tenant) => !q || tenant.name.toLowerCase().includes(q.toLowerCase()));
    },

    setMainCategories(categories = []) {
      if (this.mainCategoryOffset === 0) {
        this.mainCategories = [];
      }

      const newCategories = categories
        .filter((newCategory) =>
          this.mainCategories.every((category) => category.id !== newCategory.id),
        )
        .map((item) => ({ id: item.id, name: item.attributes.name }));

      this.mainCategories = this.mainCategories.concat(newCategories);
    },

    setSubCategories(categories = []) {
      if (this.subCategoryOffset === 0) {
        this.subCategories = [];
      }

      const newCategories = categories
        .filter((newCategory) =>
          this.subCategories.every((category) => category.id !== newCategory.id),
        )
        .map((item) => ({ id: item.id, name: item.attributes.name }));

      this.subCategories = this.subCategories.concat(newCategories);
    },

    handleTenantsSelect(values) {
      if (this.selectedClubs && this.selectedClubs.length && values.length) {
        this.selectedClubs = [];
        this.$updateQuery({ clubs: undefined });
      }

      this.selectedTenants = values;
      this.$emit("selected-tenants", this.selectedTenants);
      this.$updateQuery({
        offset: 0,
        tenant: values.length && !this.isAllTenantsSelected ? values.join(",") : undefined,
      });

      (this.clubsDD || this.clubsRadioDD) && this.loadClubs();
    },

    handleClubsSelect(values) {
      if (!values) {
        this.selectedClubs = [];

        return;
      }

      this.selectedClubs = values;

      if (this.clubsRadioDD && this.allowSystemsManagement) {
        this.$updateQuery({
          offset: 0,
          clubId: values.length ? values[0] : undefined,
        });
      } else {
        this.$updateQuery({
          offset: 0,
          clubs: values.length ? values.join(",") : undefined,
        });
      }
    },

    handleEventFiltersSelect(values) {
      const currentDate = new Date();
      const exceptions = [
        "hasMlPersonalHighlights",
        "hasPersonalHighlights",
        "hasPano",
        "noPano",
        "has5Players",
        "has8Players",
        "has11Players",
        "hasGeoBlocking",
        "noGeoBlocking",
        "hasMixerHighlights",
        "isMale",
        "isFemale",
        "isMixed",
      ];
      const initialStatuses = "upcoming,live,initializing";
      const additionalVodStatuses = { movingToVOD: MOVING_TO_VOD, statusDeleted: DELETED };

      if (!values) {
        this.selectedFilters = [];

        return;
      }

      const query = this.filterNames.reduce((acc, key) => {
        if (exceptions.includes(key)) {
          acc[key] = values.find((el) => el === key) ? true : undefined;
        } else if (
          (key === "movingToVOD" || key === "statusDeleted") &&
          this.$route.query.status !== initialStatuses &&
          this.$route.query.status !== LIVE
        ) {
          const selectedStatus = values
            .filter((el) => el === key)
            .map((item) => additionalVodStatuses[item]);

          if (acc.status && acc.status !== VOD && selectedStatus?.length) {
            acc.status = `${acc.status},${selectedStatus[0]}`;
          } else if (!acc.status || acc.status === VOD) {
            acc.status = selectedStatus[0] || VOD;
          }
        } else {
          acc[key] = values.find((el) => el === key) ? 1 : undefined;
        }

        if (acc.vodDeletedAt) {
          acc.vodDeletedAt =
            moment(currentDate.setDate(currentDate.getDate() + 30))
              .startOf("day")
              .unix() || undefined;
        }

        if (acc.minDuration) {
          acc.minDuration = 30; // 30 minutes
        }

        if (acc.maxDuration) {
          acc.maxDuration = 3 * 60; // 3 hours
        }

        return acc;
      }, {});

      this.selectedFilters = values;
      this.$updateQuery(query);
    },

    handleMainCategoriesSelect(values) {
      if (!values) {
        this.selectedMainCategories = [];

        return;
      }

      this.selectedMainCategories = values;

      let allCategories = this.selectedMainCategories;

      if (this.selectedSubCategories.length && this.$route.query.categories) {
        allCategories = allCategories.concat(this.selectedSubCategories);
      }

      this.$updateQuery({
        offset: 0,
        categories: allCategories.join(",") || undefined,
      });
    },

    handleSubCategoriesSelect(values) {
      if (!values) {
        this.selectedSubCategories = [];

        return;
      }

      this.selectedSubCategories = values;

      let allCategories = this.selectedSubCategories;

      if (this.selectedMainCategories.length) {
        allCategories = allCategories.concat(this.selectedMainCategories);
      }

      this.$updateQuery({
        offset: 0,
        categories: allCategories.join(",") || undefined,
      });
    },

    handleExtensionsSelect(values) {
      if (!values) {
        this.selectedExtensions = [];

        return;
      }

      const filterExtensions = this.extensions.filter(
        (extension) => values.indexOf(extension.id) !== -1,
      );

      this.selectedExtensions = filterExtensions.map((item) => item.id);
      const selectedExtensionsIds = filterExtensions.map((item) => item.id);

      this.$updateQuery({ extensions: selectedExtensionsIds.join(",") || undefined });
    },

    handleFilterSelect({ filterName, values }) {
      this.init();

      switch (filterName) {
        case "Tenants":
          return this.handleTenantsSelect(values);
        case "Clubs":
          return this.handleClubsSelect(values);
        case "Event Filters":
          return this.handleEventFiltersSelect(values);
        case "Main":
          return this.handleMainCategoriesSelect(values);
        case "Sub":
          return this.handleSubCategoriesSelect(values);
        case "Extensions":
          return this.handleExtensionsSelect(values);
      }
    },

    resetEventFilter() {
      for (const drop in this.$refs) {
        if (drop.indexOf("FilterSelect")) {
          this.$refs[drop]?.clear();
        }
      }
    },
  },
};
</script>

<style lang="stylus"></style>
