import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/modules/routes";

import tempGuard from "./guards/temp";
import titleGuard from "./guards/title";
import tenantGuard from "./guards/tenant";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/logout",
      redirect: (to) => ({ path: "/login", query: { logout: true } }),
    },
    {
      path: "/auth/redirect/",
      redirect: (to) => {
        if (to.query.mode === "verifyEmail" && to.query.oobCode) {
          return {
            name: "VerifyEmail",
            query: { oobCode: to.query.oobCode },
          };
        }
        return "/login";
      },
    },
    ...routes,
  ],
});

router.beforeResolve(tempGuard);
router.beforeResolve(titleGuard);
router.beforeResolve(tenantGuard);

export default router;
