var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-provider",
    { attrs: { tag: "label", name: _vm.name } },
    [
      _c("input", {
        ref: "radio",
        staticClass: "radio-component",
        attrs: { type: "radio", disabled: _vm.disabled },
        domProps: { checked: _vm.value, value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
        },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }