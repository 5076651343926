import { AuthApi } from "@/api";
import toaster from "@/plugins/toaster";
import firebase from "@/plugins/firebase";
import Bugsnag from "@bugsnag/js";
import { root } from "@/constants";
const { USER, ACCOUNTADMIN } = root.role;

const state = () => {
  return {
    data: null,
  };
};

const getters = {
  isAuthenticated(state, getters, rootState) {
    const hasUser = Boolean(rootState.user.data?.attributes?.role);

    return hasUser;
  },
};

const actions = {
  async login(store, { email, password }) {
    const handleUserResponse = (user) => {
      if (user.data?.attributes.isActive === false) {
        toaster.error("You are not allowed to login with this user. User is inactive.");

        return Promise.reject(new Error(`You are not allowed to login with this user.`));
      }

      if (user.data?.attributes.role === USER) {
        try {
          toaster.error("User account is not an administrator.");
        } catch (error) {
          Bugsnag.notify(error);

          return Promise.reject(new Error(`You are not allowed to login with this user.`));
        }
      }

      store.dispatch("loginWithUser", user);
    };

    try {
      const user = await AuthApi.login({ email, password });

      handleUserResponse(user);
    } catch (err) {
      console.error("login", err);
      if (err && err.status !== 404) {
        store.dispatch("logout");

        return Promise.reject(new Error("Username or password is incorrect"));
      }

      // try to sign in user to Firebase when user does not exist in ABE db
      await firebase
        .signInUserWithEmailAndPassword({ email, password })
        .then(async (user) => {
          AuthApi.setAuthToken(user.token);

          const abeUser = await AuthApi.getUsersMe();

          const newUser = {
            token: user.token,
            data: {
              ...user.data,
              attributes: {
                ...user.data.attributes,
                role: abeUser.data?.attributes.role,
                tenants: abeUser.data?.attributes.tenants,
                provider: abeUser.data?.attributes.provider,
              },
            },
          };

          handleUserResponse(newUser);
        })
        .catch((error) => {
          console.error(error);

          if (error.code === "auth/multi-factor-auth-required") {
            return Promise.reject(error);
          }

          return Promise.reject(new Error("Username or password is incorrect"));
        });
    }
  },
  loginWithUser(store, user) {
    const role = user.data.attributes.role;

    AuthApi.setAuthToken(user.token);
    AuthApi.setAuthorizationHeader(user.token);

    store.commit("user/SET_USER", user.data, { root: true });

    if (role === ACCOUNTADMIN) {
      const defaultTenant = user.data.attributes.tenants[0];
      const currentTenant = store.rootState.user.activeTenant || defaultTenant;

      store.commit("user/SET_ACTIVE_TENANT", currentTenant, { root: true });
    }
  },
  async loginWithCode(store, { error, code }) {
    try {
      const data = await firebase.signInWithTotp(error, code);
      const user = data.user;

      AuthApi.setAuthToken(user.accessToken);

      const abeUser = await AuthApi.getUsersMe();

      if (abeUser.data?.attributes.isActive === false) {
        toaster.error("You are not allowed to login with this user. User is inactive.");

        return Promise.reject(new Error(`You are not allowed to login with this user.`));
      }

      if (abeUser.data?.attributes.role === USER) {
        try {
          toaster.error("User account is not an administrator.");
        } catch (error) {
          Bugsnag.notify(error);

          return Promise.reject(new Error(`You are not allowed to login with this user.`));
        }
      }

      const role = abeUser.data?.attributes?.role;

      AuthApi.setAuthToken(user.accessToken);
      AuthApi.setAuthorizationHeader(user.accessToken);
      store.commit(
        "user/SET_USER",
        {
          ...user,
          attributes: abeUser.data?.attributes,
        },
        { root: true },
      );

      if (role === ACCOUNTADMIN) {
        const defaultTenant = abeUser.data.attributes.tenants[0];
        const currentTenant = store.rootState.user.activeTenant || defaultTenant;

        store.commit("user/SET_ACTIVE_TENANT", currentTenant, { root: true });
      }
    } catch (error) {
      console.error("loginWithCode", error);
      return Promise.reject(new Error("Invalid code"));
    }
  },
  async logout(store) {
    const auth = firebase.getAuth();

    if (auth) {
      await firebase.signOut(auth);
    }

    AuthApi.logout();
    store.commit("user/SET_USER", null, { root: true });
    store.commit("user/SET_ACTIVE_TENANT", null, { root: true });
    store.commit("user/SET_TEMPORARY_SYSTEM_TAB", [], { root: true });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
