export default [
  {
    name: "Inventory",
    path: "/inventory",
    meta: {
      title: "Inventory",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["superadmin", "accountadmin"],
      group: "Inventory",
    },
    redirect: {
      name: "All Systems",
    },
    component: (resolve) => require(["@inventory/pages/index.vue"], resolve),
    children: [
      {
        name: "All Systems",
        path: "/inventory/systems",
        meta: {
          title: "Fleet Management",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["superadmin", "accountadmin", "admin"],
          group: "Inventory",
        },
        component: (resolve) => require(["@inventory/pages/systems/index.vue"], resolve),
      },
      {
        name: "Pinned Systems",
        path: "/inventory/pinned-systems",
        meta: {
          title: "Fleet Management",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["superadmin"],
          group: "Inventory",
        },
        component: (resolve) => require(["@inventory/pages/pinned-systems.vue"], resolve),
      },
      {
        name: "Temporary Pinned Systems",
        path: "/inventory/pinned-systems/:name",
        meta: {
          title: "Fleet Management",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["superadmin"],
          group: "Inventory",
        },
        component: (resolve) => require(["@inventory/pages/pinned-systems.vue"], resolve),
      },
    ],
  },
  {
    name: "SW Versions",
    path: "/sw-versions",
    meta: {
      title: "SW Versions",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["superadmin"],
      group: "Inventory",
    },
    redirect: {
      name: "Packages",
    },
    component: (resolve) => require(["@inventory/pages/sw-versions.vue"], resolve),
    children: [
      {
        name: "Packages",
        path: "/sw-versions/packages",
        meta: {
          title: "SW Versions",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["superadmin"],
          group: "Inventory",
        },
        component: (resolve) => require(["@inventory/pages/packages.vue"], resolve),
      },
      {
        name: "Installers",
        path: "/sw-versions/installers",
        meta: {
          title: "SW Versions",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["superadmin"],
          group: "Inventory",
        },
        component: (resolve) => require(["@inventory/pages/installers.vue"], resolve),
      },
    ],
  },
  {
    name: "Upgrades",
    path: "/upgrades",
    meta: {
      title: "Upgrades",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["superadmin"],
      group: "Inventory",
    },
    component: (resolve) => require(["@inventory/pages/upgrades.vue"], resolve),
  },
  {
    name: "Scoreboard",
    path: "/inventory/systems/:id/scoreboard",
    meta: { title: "Scoreboard", requiresAuth: true, accessRoles: ["superadmin"] },
    component: (resolve) => require(["@inventory/pages/systems/_id/scoreboard.vue"], resolve),
  },
];
